import axios from "axios";

export const getRoadPits = async () => {
    try {
        const res = await axios.get("/detection/potholes/");
        return await res.data;
    } catch (err) {
        throw new Error("Error getRelatedSearchesTable");
    }
}

export const getMapPits = async (filters={}) => {
    try {
        const query = new URLSearchParams(filters);
        const res = await axios.get(`/detection/potholes/map/?${query}`);
        return await res.data;
    } catch (err) {
        throw new Error("Error getRelatedSearchesTable");
    }
}

export const getMapRoadSigns = async (filters={}) => {
    try {
        const query = new URLSearchParams(filters);
        const res = await axios.get(`/detection/potholes/map_znaki/?${query}`);
        return await res.data;
    } catch (err) {
        throw new Error("Error getRelatedSearchesTable");
    }
}

export const changePitStatus = async (id: number, status: string) => {
    try {
        const res = await axios.post(`/detection/potholes/${id}/change_status/`, {id, status});
        return await res.data;
    } catch (err) {
        throw new Error("Error getRelatedSearchesTable");
    }
}

export const getStatistics = async () => {
    try {
        const res = await axios.get("/detection/statistics/");
        return await res.data;
    } catch (err) {
        throw new Error("Error getRelatedSearchesTable");
    }
}



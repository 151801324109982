import { useLocation} from "react-router-dom";
import { List, ListItem, Collapse } from "@mui/material";
import {useTheme} from "@mui/material/styles";
import * as React from "react";
import {FC, useContext, useEffect, useState} from "react";
import {AuthContext} from "../../context/AuthContext";
import Item from "./Item";
import navLinks from "../../services/navLinks";

const ItemsList: FC<{open: boolean}> = ({open=false}) => {
    const {me: {permissions}} = useContext(AuthContext);
    const {pathname} = useLocation();
    const [openSubMenus, setOpenSubMenus] = useState<Record<string, boolean>>({});

    useEffect(() => {
        if (pathname) {
            navLinks.forEach(item => {
                if (pathname.includes(item.key)) {
                    setOpenSubMenus(prevState => ({ ...prevState, [item.key]: true }));
                } else if (item.children && item.children.length > 0) {
                    item.children.forEach(child => {
                        if (pathname.includes(child.key)) {
                            setOpenSubMenus(prevState => ({ ...prevState, [item.key]: true }));
                        }
                    })
                }
            })
        }
    }, [])

    const handleToggleSubMenu = (key: string) => {
        setOpenSubMenus(prevState => ({ ...prevState, [key]: !prevState[key] }));
    };
    const theme = useTheme();
    return (
        <List>
            {navLinks.map((item, index) => {
                const disabled = item.permission ? !permissions[item.permission] : false;
                return (
                    <React.Fragment key={index}>
                        <ListItem disablePadding sx={{ display: "block" }}>
                            <Item disabled={disabled} item={item} handleToggleSubMenu={handleToggleSubMenu} theme={theme} open={open} openSubMenus={openSubMenus} />
                        </ListItem>
                        {item.children && (
                            <Collapse in={openSubMenus[item.key]} timeout="auto" unmountOnExit>
                                <List component="div" disablePadding>
                                    {item.children.map((child, childIndex) => {
                                        const disabled = child.permission ? !permissions[child.permission] : false;
                                        return <Item key={childIndex} disabled={disabled} item={child} handleToggleSubMenu={handleToggleSubMenu} theme={theme} open={open} />
                                    })}
                                </List>
                            </Collapse>
                        )}
                    </React.Fragment>
                )
            })}
        </List>
    )
}

export default ItemsList;
import React, {useContext, useEffect, useMemo, useState} from "react";
import {Box, Button, ButtonGroup, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import {DataGrid, GridSingleSelectColDef} from "@mui/x-data-grid";
import ModalImage from "react-modal-image";
import dayjs from "dayjs";
import {AuthContext} from "../../context/AuthContext";
import {Row} from "../../services/common-types";
import {getReport} from "./fetchers";


const monthHeaders = [
    {
        key: "id",
        label: "id"
    },
    {
        key: "name",
        label: "name"
    },
    {
        key: "email",
        label: "email"
    },
    {
        key: "position",
        label: "position"
    },
    {
        key: "place",
        label: "place"
    },
    {
        key: "all_defects_month",
        label: "all_defects"
    },
    {
        key: "inwork_defects_month",
        label: "inwork_defects"
    },
    {
        key: "repaired_defects_month",
        label: "repaired_defects"
    },
    {
        key: "percent_defects_month",
        label: "percent_defects"
    }
]

const weekHeaders = [
    {
        key: "id",
        label: "id"
    },
    {
        key: "name",
        label: "name"
    },
    {
        key: "email",
        label: "email"
    },
    {
        key: "position",
        label: "position"
    },
    {
        key: "place",
        label: "place"
    },
    {
        key: "all_defects_week",
        label: "all_defects"
    },
    {
        key: "inwork_defects_week",
        label: "inwork_defects"
    },
    {
        key: "repaired_defects_week",
        label: "repaired_defects"
    },
    {
        key: "percent_defects_week",
        label: "percent_defects"
    }
]

const Report = () => {
    const { t } = useTranslation();
    const {setBreadcrumbs} = useContext(AuthContext);
    const [rows, setRows] = useState<Row[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    // const [columns, setColumns] = useState<GridSingleSelectColDef[]>([]);
    const [period, setPeriod] = useState("week");

    const getItems = async () => {
        try {
            setIsLoading(true);
            const rows = await getReport();
            // const cols = headers.map((col: any) => ({
            //     ...col,
            //     field: col.key,
            //     headerName: col.label,
            //     flex: col.key === "id" ? 0 : 1,
            //     width: col.key === "id" ? 50 : 125
            // }))
            setRows(rows);
            // @ts-ignore
            // setColumns(cols);
        } catch (err) {
            console.error(err);
        } finally {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        getItems();
    }, [])

    const columns: any = useMemo(() => {
        let cols = [];
        if (period === "week") {
            cols = weekHeaders;
        } else {
            cols = monthHeaders;
        }
        cols = cols.map((col: any) => ({
            ...col,
            field: col.key,
            headerName: t(col.label),
            flex: col.key === "id" ? 0 : 1,
            width: col.key === "id" ? 50 : 125
        }))
        return cols;
    }, [period])

    return (
        <Box>
            <Box sx={{display: "flex", justifyContent: "space-between", alignItems: "center", mb: {xs: "1rem", md: "1.5rem"}}}>
                <Typography variant="h1" sx={{fontSize: {xs: "1.5rem", md: "2rem"}}}>{t("report_for_week_month")}</Typography>
            </Box>
            <ButtonGroup color="info" variant="outlined" aria-label="Basic button group" sx={{mb: "1rem"}}>
                <Button
                    onClick={() => setPeriod("week")}
                    sx={{backgroundColor: period === "week" ? "brandColor.primary" : "",
                        color: period === "week" ? "white" : "",
                        p: ".375rem 1.25rem", fontSize: "1rem"}}
                >
                    {t("week")}
                </Button>
                <Button
                    onClick={() => setPeriod("month")}
                    sx={{backgroundColor: period === "month" ? "brandColor.primary" : "",
                        color: period === "month" ? "white" : "",
                        p: ".375rem 1.25rem", fontSize: "1rem"}}
                >
                    {t("month")}
                </Button>
            </ButtonGroup>
            <DataGrid
                sx={{
                    zIndex: "1001",
                    ".MuiDataGrid-virtualScrollerRenderZone": {
                        transform: "none !important"
                    }
                }}
                rows={rows}
                columns={columns}
                initialState={{
                    pagination: {
                        paginationModel: { page: 0, pageSize: 10 },
                    },
                }}
                pageSizeOptions={[10, 25, 50, 100]}
                autoHeight
                loading={isLoading}
                hideFooterSelectedRowCount
                componentsProps={{
                    pagination: {
                        labelRowsPerPage: t("rows_per_page")
                    }
                }}
            />
        </Box>
    )
}

export default Report;
import React, {useContext, useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {Box, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import RepairerForm from "./RepairerForm";
import {getRepairer} from "./services/fetchers";
import {AuthContext} from "../../context/AuthContext";

const RepairerDetails = () => {
    const { t } = useTranslation();
    const {setBreadcrumbs} = useContext(AuthContext);
    const navigate = useNavigate();
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const {id} = useParams();
    const isEdit = id !== undefined;

    useEffect(() => {
        setBreadcrumbs([
            {label: "Исполнители", href: "/performers"},
            ...(isEdit
                ? [{label: (data as any)?.name, href: ""}]
                : [])
        ]);
        return () => setBreadcrumbs([])
    }, [data])

    useEffect(() => {
        if (id) {
            console.log("Repairer id", id);
            (async () => {
                try {
                    setLoading(true);
                    const data = await getRepairer(id);
                    setData(data);
                } catch (e) {
                    navigate("/performers");
                } finally {
                    setLoading(false);
                }
            })()
        }
    }, [id])

    return (
        <Box>
            <Box sx={{display: "flex", justifyContent: "space-between", alignItems: "center", mb: {xs: "1rem", md: "1.5rem"}}}>
                <Typography variant="h1" sx={{fontSize: {xs: "1.5rem", md: "2rem"}}}>
                    { isEdit
                        ? t("edit_repairer")
                        : t("create_repairer")}
                </Typography>
            </Box>
            { isEdit
                ? !loading &&
                    <RepairerForm data={data} />
                : <RepairerForm />
            }
        </Box>
    )
}

export default RepairerDetails;
import axios from "axios";

// eslint-disable-next-line import/prefer-default-export
export const getMe = async () => {
    try {
        const res = await axios.get("/users/me/");
        return await res.data;
    } catch (err: any) {
        throw new Error("User is unauthorized!");
    }
}


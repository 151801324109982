import {useForm} from "react-hook-form";
import {FC, useContext} from "react";
import {useTranslation} from "react-i18next";
import {Box} from "@mui/material";
import {useNavigate} from "react-router-dom";
import Fields from "../../components/Form/Fields";
import formFields from "./services/formFields";
import CustomButton from "../../UI/CustomButton";
import {createRepairer, editRepairer} from "./services/fetchers";
import {AuthContext} from "../../context/AuthContext";

const RepairerForm: FC<any> = ({data={}}) => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const {setNotification} = useContext(AuthContext);
    const {
        control,
        handleSubmit,
        getValues,
        formState: { errors },
    } = useForm<any>({
        defaultValues: data
    });
    const isEdit = Object.keys(data).length > 0;

    const onSubmit = async (backendData: any) => {
        try {
            if (isEdit) {
                await editRepairer(backendData);
            } else {
                await createRepairer(backendData);
            }
            navigate("/performers");
            setNotification({
                open: true,
                type: "success",
                message: isEdit
                    ? t("repairer_created_successfully")
                    : t("repairer_created_successfully")
            })
        } catch (e) {
            console.error(e);
            setNotification({
                open: true,
                type: "error",
                message: t("status_change_failed")
            })
        }
    }

    const formValues = getValues();

    return (
        <Box sx={{display: "flex", flexDirection: {xs: "column", md: "row"}, width: "100%"}}>
            <form onSubmit={handleSubmit(onSubmit)} style={{width: "100%"}}>
                <Fields formFields={formFields} errors={errors} formValues={formValues} control={control} />
            </form>
            <CustomButton
                style={{marginTop: {xs: "1rem", md: "0"}}}
                label={t("submit")}
                onChange={handleSubmit(onSubmit)}
            />
        </Box>
    )
}

export default RepairerForm;
import Box from "@mui/material/Box";
import {useTranslation} from "react-i18next";
import IconButton from "@mui/material/IconButton";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import Brightness5OutlinedIcon from "@mui/icons-material/Brightness5Outlined";
import AccountCircleRoundedIcon from "@mui/icons-material/AccountCircleRounded";
import * as React from "react";
import {useTheme} from "@mui/material/styles";
import Toolbar from "@mui/material/Toolbar";
import MenuIcon from "@mui/icons-material/Menu";
import {FC, useContext} from "react";
import Typography from "@mui/material/Typography";
import ItemsList from "./ItemsList";
import {AppBar, Drawer, DrawerHeaderDesktop} from "./constants";
import {DrawerProps} from "./type";
import Localization from "../Localization";
import TextLogo from "../../UI/TextLogo";
import CustomButton from "../../UI/CustomButton";
import {AuthContext} from "../../context/AuthContext";

const DrawerDesktop: FC<DrawerProps> = ({logout}) => {
    const { t } = useTranslation();
    const {toggleColorMode} = useContext(AuthContext);
    const theme = useTheme();
    const [open, setOpen] = React.useState(true);

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    return (
        <>
            <AppBar position="fixed" open={open} sx={{display: {xs: "none", md: "flex" }, zIndex: 99, borderBottom: "1px solid",
                borderColor: "border.primary", }}>
                <Toolbar sx={{justifyContent: "space-between", backgroundColor: "background.default" }}>
                    <Box sx={{display: "flex", alignItems: "center", ml: !open ? "64px" : 0  }}>
                        <TextLogo size="24px"/>
                    </Box>
                    <Box sx={{display: "flex", alignItems: "center", justifyContent: "space-between", gap: "16px"}}>
                        <Localization />
                        <IconButton onClick={toggleColorMode} color="inherit">
                            {theme.palette.mode === "dark" ? <Brightness5OutlinedIcon /> : <DarkModeOutlinedIcon />}
                        </IconButton>
                        <IconButton disableRipple onClick={logout} color="inherit" sx={{display: "flex", alignItems: "center", gap: "8px"}}>
                            <AccountCircleRoundedIcon sx={{color: "customBackground.inverse", width: "32px", height: "32px"}} />
                            <Typography sx={{fontWeight: 500}}>
                                Admin
                            </Typography>
                        </IconButton>
                        <CustomButton style={{backgroundColor: "red", ":hover": {backgroundColor: "red", opacity: ".75"}}}
                            label={t("logout")} onChange={logout}
                        />
                    </Box>
                </Toolbar>
            </AppBar>
            <Drawer variant="permanent" open={open} sx={{display: {xs: "none", md: "flex", }, zIndex: 100}}>
                <DrawerHeaderDesktop sx={{justifyContent: "space-between"}}>
                    { open ?
                        <>
                            <Box sx={{ height: "40px", ml: "1rem", display: "flex", alignItems: "center"}}>
                                <Typography color="brandColor" sx={{fontWeight: "600", fontSize: "1.5rem"}}>
                                    JolQalai
                                </Typography>
                            </Box>
                            <IconButton onClick={handleDrawerClose}>
                                <ChevronLeftIcon sx={{ color: "text.primary" }}/>
                            </IconButton>
                        </>
                        :
                        <Box sx={{ height: "45px", ml: "1rem"}}>
                            <IconButton
                                color="inherit"
                                aria-label="open drawer"
                                onClick={handleDrawerOpen}
                                edge="start"
                                sx={{
                                    ...(!open ? { marginRight: 5 } : { display: "none" }),
                                    padding: "8px"
                                }}
                            >
                                <MenuIcon sx={{ color: "text.primary" }} />
                            </IconButton>
                        </Box>

                    }
                </DrawerHeaderDesktop>
                <ItemsList open={open} />
                {
                    open && <Typography sx={{ position: "fixed", bottom: "16px", alignSelf: "center", textAlign: "center" }}>
                        Алматы<br/>
                        © 2024 JolQalai
                    </Typography>
                }
            </Drawer>
        </>
    )
}

export default DrawerDesktop;
import {FormControl, InputLabel, MenuItem, Select} from "@mui/material"
import {FC} from "react";

type Option = string[] | Record<string, any>[]


type SelectFieldType = {
    label: string,
    options: Option,
    value: string,
    onChange: (value: string) => void
}

const SelectField: FC<SelectFieldType> = ({label="", options=[], value="", onChange=(value: string) => {}}) => (
    <FormControl fullWidth sx={{maxWidth: {xs: "100%", md: "280px"}, width: "100%"}}>
        <InputLabel id="demo-simple-select-label">{label}</InputLabel>
        <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={value}
            label="Age"
            onChange={e => onChange(e.target.value)}
        >
            { options.map((option: any, index: number) => {
                const label = typeof option === "object" ? option.label : option;
                const value = typeof option === "object" ? option.value : option;
                return (
                    <MenuItem key={index} value={value}>{label}</MenuItem>
                )
            })}
        </Select>
    </FormControl>
)

export default SelectField
import React, {useContext, useEffect, useState} from "react";
import {Box, Typography} from "@mui/material";
import {DataGrid, GridSingleSelectColDef} from "@mui/x-data-grid";
import ModalImage from "react-modal-image";
import dayjs from "dayjs";
import {useTranslation} from "react-i18next";
import {AuthContext} from "../../context/AuthContext";
import {Row} from "../../services/common-types";
import {getRoadPits} from "./services/fetchers";

const RoadPits = () => {
    const { t } = useTranslation();
    const {setBreadcrumbs} = useContext(AuthContext);
    const [rows, setRows] = useState<Row[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [columns, setColumns] = useState<GridSingleSelectColDef[]>([]);

    const headers = [
        {
            key: "id",
            label: t("id")
        },
        {
            key: "latitude",
            label: t("latitude")
        },
        {
            key: "longitude",
            label: t("longitude")
        },
        {
            key: "level",
            label: t("level"), // Тип
        },
        {
            key: "status",
            label: t("status")
        },
        {
            key: "created_at",
            label: t("created_at"),
            renderCell: (cellParams: any) => <p>{dayjs(cellParams.value).format("DD, MMMM YYYY")}</p>
        },
        {
            key: "image",
            label: "Фото",
            renderCell: (cellParams: any) => (
                <Box sx={{width: "70%"}}>
                    <ModalImage
                        // className={styles['table__image']}
                        large={cellParams.value}
                        medium={cellParams.value}
                        small={cellParams.value}
                        alt=''
                    />
                </Box>
            )
        }
    ]

    const getItems = async () => {
        try {
            setIsLoading(true);
            const rows = await getRoadPits();
            const cols = headers.map((col: any) => ({
                ...col,
                field: col.key,
                headerName: col.label,
                flex: col.key === "id" ? 0 : 1,
                width: col.key === "id" ? 50 : 125
            }))
            // cols.push({
            //     field: "actions",
            //     headerName: "Actions",
            //     width: 100,
            //     // @ts-ignore
            //     sortable: false, // Отключение сортировки для этой колонки
            //     filterable: false, // Отключение фильтрации для этой колонки
            //     disableColumnMenu: true, // Отключение меню колонки
            //     renderCell: (cellParams: any) => <CustomMenuCell cellParams={cellParams} />
            // })
            setRows(rows);
            // @ts-ignore
            setColumns(cols);
            // setHasAccess(data.has_full_access);
        } catch (err) {
            console.error(err);
        } finally {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        getItems();
    }, [])

    return (
        <Box>
            <Box sx={{display: "flex", justifyContent: "space-between", alignItems: "center", mb: {xs: "1rem", md: "1.5rem"}}}>
                <Typography variant="h1" sx={{fontSize: {xs: "1.5rem", md: "2rem"}}}>{t("pits")}</Typography>
            </Box>
            <Box sx={{height: "100%", maxHeight: {xs: "400", md: "1000px"}, overflowY: "scroll"}}>
                <DataGrid
                    sx={{
                        zIndex: "1001",
                        ".MuiDataGrid-virtualScrollerRenderZone": {
                            transform: "none !important"
                        }
                    }}
                    rows={rows}
                    columns={columns}
                    initialState={{
                        pagination: {
                            paginationModel: { page: 0, pageSize: 10 },
                        },
                    }}
                    pageSizeOptions={[10, 25, 50, 100]}
                    loading={isLoading}
                    hideFooterSelectedRowCount
                    componentsProps={{
                        pagination: {
                            labelRowsPerPage: t("rows_per_page")
                        }
                    }}
                />
            </Box>
        </Box>
    )
}

export default RoadPits;
import {Box, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import {useEffect, useState} from "react";
import {getStatistics} from "../RoadPits/services/fetchers";
import "../../assets/styles/statistics.css";

const Statistics = () => {
    const { t } = useTranslation();
    const [data, setData] = useState<any>({});

    useEffect(() => {
        (async () => {
            try {
                const res = await getStatistics();
                setData(res);
            } catch (e) {
                console.error("error", e);
            }
        })()
    }, [])

    const {all_potholes_count,
        new_potholes_count,
        repaired_potholes_count,
        small_potholes_count,
        medium_potholes_count,
        large_potholes_count,
        line_potholes_count,
        all_cars_count,
        active_cars_count,
        inwork_potholes_count,
        arrow_potholes_count} = data;
    return (
        <>
            <div className="container">
                <Box className="box large-box" sx={{p: {xs: "1rem", md: "2rem"}, fontSize: {xs: "1.5rem", md: "2.5rem"}}}>
                    {t("all_pits")}
                    <Box sx={{width: "100%", height: "70%", display: "flex", alignItems: "center",
                        textAlign: "center"}}>
                        <Box sx={{m: "auto", display: "flex", alignItems: "center",
                            textAlign: "center"}}
                        >
                            <Typography sx={{fontSize: {xs: "3rem", md: "7rem"}, fontWeight: "bold"}}
                            >
                                {all_potholes_count}
                            </Typography>
                        </Box>
                    </Box>
                </Box>
                <Box className="box small-box">
                    <Box sx={{height: "50%", p: {xs: ".5rem", md: "1rem"}, fontSize: {xs: "1rem", md: "1.25rem"}}}>
                        {t("big_pits")}
                        <Box sx={{width: "100%", height: "70%", display: "flex", alignItems: "center",
                            textAlign: "center"}}>
                            <Box sx={{m: "auto", display: "flex", alignItems: "center",
                                textAlign: "center"}}
                            >
                                <Typography sx={{fontSize: {xs: "2.5rem", md: "3.5rem"}, fontWeight: "semibold"}}
                                >
                                    {large_potholes_count}
                                </Typography>
                            </Box>
                        </Box>
                    </Box>
                    <Box sx={{borderTop: "2px solid #4A00E0", height: "50%", p: {xs: ".5rem", md: "1rem"}, fontSize: {xs: "1rem", md: "1.25rem"}}}>
                        {t("middle_pits")}
                        <Box sx={{width: "100%", height: "70%", display: "flex", alignItems: "center",
                            textAlign: "center"}}>
                            <Box sx={{m: "auto", display: "flex", alignItems: "center",
                                textAlign: "center"}}
                            >
                                <Typography sx={{fontSize: {xs: "2.5rem", md: "3.5rem"}, fontWeight: "semibold"}}
                                >
                                    {medium_potholes_count}
                                </Typography>
                            </Box>
                        </Box>
                    </Box>
                </Box>
                <Box className="box small-box">
                    <Box sx={{height: "50%", p: {xs: ".5rem", md: "1rem"}, fontSize: {xs: "1rem", md: "1.25rem"}}}>
                        {t("small_pits")}
                        <Box sx={{width: "100%", height: "70%", display: "flex", alignItems: "center",
                            textAlign: "center"}}>
                            <Box sx={{m: "auto", display: "flex", alignItems: "center",
                                textAlign: "center"}}
                            >
                                <Typography sx={{fontSize: {xs: "2.5rem", md: "3.5rem"}, fontWeight: "semibold"}}
                                >
                                    {small_potholes_count}
                                </Typography>
                            </Box>
                        </Box>
                    </Box>
                    <Box sx={{borderTop: "2px solid #4A00E0", height: "50%", p: {xs: ".5rem", md: "1rem"}, fontSize: {xs: "1rem", md: "1.25rem"}}}>
                        {t("pedestrian_lines")}
                        <Box sx={{width: "100%", height: "60%", display: "flex", alignItems: "center",
                            textAlign: "center"}}>
                            <Box sx={{m: "auto", display: "flex", alignItems: "center",
                                textAlign: "center"}}
                            >
                                <Typography sx={{fontSize: {xs: "2.5rem", md: "3.5rem"}, fontWeight: "semibold"}}
                                >
                                    {line_potholes_count}
                                </Typography>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </div>
            <div className="container-2">
                <Box className="box large-box" sx={{p: {xs: "1rem", md: "2rem"}, fontSize: {xs: "1.5rem", md: "2.5rem"}}}>
                    {t("repaired_pits")}
                    <Box sx={{width: "100%", height: "70%", display: "flex", alignItems: "center",
                        textAlign: "center"}}>
                        <Box sx={{m: "auto", display: "flex", alignItems: "center",
                            textAlign: "center"}}
                        >
                            <Typography sx={{fontSize: {xs: "3rem", md: "7rem"}, fontWeight: "bold"}}
                            >
                                {repaired_potholes_count}
                            </Typography>
                        </Box>
                    </Box>
                </Box>
                <Box className="box small-box">
                    <Box sx={{height: "50%", p: {xs: ".5rem", md: "1rem"}, fontSize: {xs: ".75rem", md: "1.25rem"}}}>
                        {t("found_pits_week")}
                        <Box sx={{width: "100%", height: "70%", display: "flex", alignItems: "center",
                            textAlign: "center"}}>
                            <Box sx={{m: "auto", display: "flex", alignItems: "center",
                                textAlign: "center"}}
                            >
                                <Typography sx={{fontSize: {xs: "2.5rem", md: "3.5rem"}, fontWeight: "semibold"}}
                                >
                                    {new_potholes_count}
                                </Typography>
                            </Box>
                        </Box>
                    </Box>
                    <Box sx={{borderTop: "2px solid #4A00E0", height: "50%", p: {xs: ".5rem", md: "1rem"}, fontSize: {xs: ".75rem", md: "1.25rem"}}}>
                        {t("inwork_pits_week")}
                        <Box sx={{width: "100%", height: "70%", display: "flex", alignItems: "center",
                            textAlign: "center"}}>
                            <Box sx={{m: "auto", display: "flex", alignItems: "center",
                                textAlign: "center"}}
                            >
                                <Typography sx={{fontSize: {xs: "2.5rem", md: "3.5rem"}, fontWeight: "semibold"}}
                                >
                                    {inwork_potholes_count}
                                </Typography>
                            </Box>
                        </Box>
                    </Box>
                </Box>
                <Box className="box small-box">
                    <Box sx={{height: "50%", p: {xs: ".5rem", md: "1rem"}, fontSize: {xs: ".75rem", md: "1.25rem"}}}>
                        {t("arrow_pits")}
                        <Box sx={{width: "100%", height: "70%", display: "flex", alignItems: "center",
                            textAlign: "center"}}>
                            <Box sx={{m: "auto", display: "flex", alignItems: "center",
                                textAlign: "center"}}
                            >
                                <Typography sx={{fontSize: {xs: "2.5rem", md: "3.5rem"}, fontWeight: "semibold"}}
                                >
                                    {arrow_potholes_count}
                                </Typography>
                            </Box>
                        </Box>
                    </Box>
                    <Box sx={{borderTop: "2px solid #4A00E0", height: "50%", p: {xs: ".5rem", md: "1rem"}, fontSize: {xs: ".75rem", md: "1.25rem"}}}>
                        {t("cars")}
                        <Box sx={{width: "100%", height: "60%", display: "flex", alignItems: "center",
                            textAlign: "center"}}>
                            <Box sx={{m: "auto", display: "flex", alignItems: "center",
                                textAlign: "center"}}
                            >
                                <Typography sx={{fontSize: {xs: "2.5rem", md: "3.5rem"}, fontWeight: "semibold"}}
                                >
                                    {active_cars_count}/{all_cars_count}
                                </Typography>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </div>
        </>
    )
}

export default Statistics;
import axios from "axios";

// eslint-disable-next-line import/prefer-default-export
export const getReport = async () => {
    try {
        const res = await axios.get("/users/statistics/");
        return await res.data;
    } catch (err) {
        throw new Error("Error getRelatedSearchesTable");
    }
}
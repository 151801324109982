import React, {useContext, useEffect, useState} from "react";
import {Box, Typography} from "@mui/material";
import {DataGrid, GridSingleSelectColDef} from "@mui/x-data-grid";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import {AuthContext} from "../../context/AuthContext";
import {Row} from "../../services/common-types";
import {getRepairers} from "./services/fetchers";
import CustomMenuCell from "../../components/CustomMenuCell";
import {ReactComponent as CheckIcon} from "../../assets/icons/check.svg";
import {ReactComponent as CloseIcon} from "../../assets/icons/close-red.svg";
import CustomButton from "../../UI/CustomButton";

const headers = [
    {
        key: "id",
        label: "id"
    },
    {
        key: "name",
        label: "name"
    },
    {
        key: "username",
        label: "username"
    },
    {
        key: "email",
        label: "email"
    },
    {
        key: "position",
        label: "position"
    },
    {
        key: "place",
        label: "place"
    },
    {
        key: "is_repairer",
        label: "is_repairer",
        renderCell: (cellParams: any) => {
            console.log("is_repairer", cellParams.value)
            return <p>{cellParams.value
                ? <CheckIcon style={{width: "32px", height: "32px"}} />
                : <CloseIcon style={{width: "32px", height: "32px"}} />}</p>
        }
    },
    {
        key: "is_moderator",
        label: "is_moderator",
        renderCell: (cellParams: any) => <p>{cellParams.value
            ? <CheckIcon style={{width: "32px", height: "32px"}} />
            : <CloseIcon style={{width: "32px", height: "32px"}} />}</p>
    }
]

const Repairers = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const {setBreadcrumbs} = useContext(AuthContext);
    const [rows, setRows] = useState<Row[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [columns, setColumns] = useState<GridSingleSelectColDef[]>([]);

    // useEffect(() => {
    //     setBreadcrumbs([{label: "Ямы", href: "/"}]);
    // }, [])

    const getItems = async () => {
        try {
            setIsLoading(true);
            const rows = await getRepairers();
            const cols = headers.map((col: any) => ({
                ...col,
                field: col.key,
                headerName: t(col.label),
                flex: col.key === "id" ? 0 : 1,
                width: col.key === "id" ? 50 : 125
            }))
            cols.push({
                field: "actions",
                headerName: t("actions"),
                width: 100,
                // @ts-ignore
                sortable: false, // Отключение сортировки для этой колонки
                filterable: false, // Отключение фильтрации для этой колонки
                disableColumnMenu: true, // Отключение меню колонки
                renderCell: (cellParams: any) => <CustomMenuCell cellParams={cellParams} />
            })
            setRows(rows);
            // @ts-ignore
            setColumns(cols);
            // setHasAccess(data.has_full_access);
        } catch (err) {
            console.error(err);
        } finally {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        getItems();
    }, [])

    const goToCreatePage = () => navigate("create");

    return (
        <Box>
            <Box sx={{display: "flex", justifyContent: "space-between", alignItems: "center", mb: {xs: "1rem", md: "1.5rem"}}}>
                <Typography variant="h1" sx={{fontSize: {xs: "1.5rem", md: "2rem"}}}>{t("performers")}</Typography>
                <CustomButton label={t("create")} onChange={goToCreatePage} />
            </Box>
            <DataGrid
                sx={{
                    zIndex: "1001",
                    ".MuiDataGrid-virtualScrollerRenderZone": {
                        transform: "none !important"
                    }
                }}
                rows={rows}
                columns={columns}
                initialState={{
                    pagination: {
                        paginationModel: { page: 0, pageSize: 10 },
                    },
                }}
                pageSizeOptions={[10, 25, 50, 100]}
                autoHeight
                loading={isLoading}
                hideFooterSelectedRowCount
                componentsProps={{
                    pagination: {
                        labelRowsPerPage: t("rows_per_page")
                    }
                }}
            />
        </Box>
    )
}

export default Repairers;
import * as React from "react";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import {Alert, Snackbar} from "@mui/material";
import {Outlet} from "react-router-dom";
import {useContext} from "react";
import {AuthContext} from "../context/AuthContext";
import {DrawerHeaderDesktop} from "../components/Drawers/constants";
import Header from "../components/Header/Header";
import PrivateRoute from "../router/PrivateRoute";
import Breadcrumbs from "../components/Breadcrumbs";
import NoPermission from "../components/Errors/403";

const MainLayout = () => {
    const {noPermission, notification, handleCloseNotification} = useContext(AuthContext);

    if (noPermission) {
        return <NoPermission />
    }

    return (
        <PrivateRoute>
            <Box sx={{ display: "flex", height: "100%", backgroundColor: "customBackground.secondary"}}>
                <CssBaseline />
                <Snackbar
                    anchorOrigin={{ vertical: "top", horizontal: "center" }}
                    open={notification.open}
                    onClose={handleCloseNotification}
                    key="top center"
                    autoHideDuration={notification.duration || 3000}
                >
                    <Alert onClose={handleCloseNotification} severity={notification.type} sx={{ width: "100%" }}>
                        {typeof notification.message === "object"
                            ? (
                                <>
                                    {notification.message.map((not: string, index: number) => (
                                        <Typography sx={{fontSize: "1rem"}} key={index}>{not}</Typography>
                                    ))}
                                </>
                            ) : notification.message}
                    </Alert>
                </Snackbar>
                <Header />
                <Box component="main" color="secondary"
                    sx={{ flexGrow: 1, p: {xs: "1rem 1rem 0 1rem", md: "1.5rem 1.5rem 0 1.5rem"}, marginBottom: {xs: ".5rem", md: "1rem"}, overflow: "hidden", position: "relative" }}
                >
                    <DrawerHeaderDesktop />
                    <Breadcrumbs />
                    <Outlet />
                </Box>
            </Box>
        </PrivateRoute>
    );
}

export default MainLayout;
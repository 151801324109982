import {NavLinkType} from "./common-types";
import {ReactComponent as MapIcon} from "../assets/icons/map.svg";
import {ReactComponent as PointsIcon} from "../assets/icons/points.svg";
import {ReactComponent as StatisticsIcon} from "../assets/icons/statistics.svg";
import {ReactComponent as PeopleIcon} from "../assets/icons/people.svg";
import {ReactComponent as ReportIcon} from "../assets/icons/report.svg";
import {ReactComponent as RoadSignsIcon} from "../assets/icons/road-signs.svg";

const navLinks: Array<NavLinkType> = [
    {
        label: "statistics",
        key: "main",
        href: "/",
        Icon: StatisticsIcon
    },
    {
        label: "pits",
        key: "pits",
        href: "/pits",
        Icon: PointsIcon
    },
    {
        label: "map",
        key: "map",
        href: "/map",
        Icon: MapIcon
    },
    {
        label: "map_road_signs",
        key: "road-signs",
        href: "/road-signs",
        Icon: RoadSignsIcon
    },
    {
        label: "performers",
        key: "performers",
        href: "/performers",
        Icon: PeopleIcon
    },
    {
        label: "reports",
        key: "reports",
        href: "/reports",
        Icon: ReportIcon
    }
]

export default navLinks;
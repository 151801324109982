import React, {useContext, useEffect, useState} from "react";
import {MapContainer, Marker, Popup, TileLayer} from "react-leaflet";
import dayjs from "dayjs";
import "leaflet/dist/leaflet.css";
import ModalImage from "react-modal-image";
import {Box, Button, Modal, Typography} from "@mui/material";
import {Icon, divIcon, point} from "leaflet";
import MarkerClusterGroup from "react-leaflet-cluster";
import {useTranslation} from "react-i18next";
import { Link } from "react-router-dom";
import MarkerRedIcon from "../../assets/icons/marker-red.svg";
import MarkerBlueIcon from "../../assets/icons/marker-blue.svg";
import MarkerYellowIcon from "../../assets/icons/marker-yellow.svg";
import MarkerPedestrianIcon from "../../assets/icons/marker-pedestrian.svg";
import MarkerArrowSignIcon from "../../assets/icons/arrow-sign.svg";
import "../../assets/styles/map.css";
import {changePitStatus, getMapRoadSigns} from "../RoadPits/services/fetchers";
import {AuthContext} from "../../context/AuthContext";
import SelectField from "../../UI/InputFields/SelectField";
import FileInputField from "../../UI/InputFields/FileInputField";
import CustomButton from "../../UI/CustomButton";

const markerRedIcon = new Icon({
    iconUrl: MarkerRedIcon,
    iconSize: [48, 48]
})

const markerBlueIcon = new Icon({
    iconUrl: MarkerBlueIcon,
    iconSize: [48, 48]
})

const markerYellowIcon = new Icon({
    iconUrl: MarkerYellowIcon,
    iconSize: [48, 48]
})

const markerPedestrianIcon = new Icon({
    iconUrl: MarkerPedestrianIcon,
    iconSize: [48, 48]
})

const markerArrowSignIcon = new Icon({
    iconUrl: MarkerArrowSignIcon,
    iconSize: [48, 48]
})

const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    borderRadius: "4px",
    boxShadow: 24,
    p: 4,
};


const RoadSignsMap = () => {
    const { t } = useTranslation();
    const {setNotification} = useContext(AuthContext);
    const [markers, setMarkers] = useState([]);
    const [filters, setFilters] = useState<any>({});
    const [open, setOpen] = useState(false);
    const [imageFile, setImageFile] = useState<File | null>(null);
    const [tempPit, setTempPit] = useState<{id?: number, status?: string}>({});
    const [reFetch, setReFetch] = useState(true);
    // const [fullscreenImage, setFullscreenImage] = useState({open: false, image: ""});

    const statuses = [{
        label: t("all"),
        value: "Все"
    }, {
        label: t("new"),
        value: "Новая яма"
    }, {
        label: t("fixed"),
        value: "Исправили"
    }, {
        label: t("in_work"),
        value: "В работе"
    }];

    const levels = [{
        label: t("all"),
        value: "Все"
    }, {
        label: t("pedestrian_line_erasing"),
        value: "Разметка пешехода стирается"
    }, {
        label: t("arrow_erasing"),
        value: "Стрелка поворота стирается"
    }];

    useEffect(() => {
        if (reFetch) {
            (async () => {
                try {
                    const res = await getMapRoadSigns(filters);
                    setMarkers(res);
                    setReFetch(false);
                } catch (e) {
                    console.error("error", e);
                }
            })()
        }
    }, [reFetch])

    const getIconByLevel = (level="Разметка пешехода стирается") => {
        switch (level) {
            case "Разметка пешехода стирается":
                return markerPedestrianIcon
            case "Стрелка поворота стирается":
                return markerArrowSignIcon
            default:
                return markerBlueIcon;
        }
    }

    const getButtonLabel = (status="Новая яма") => {
        switch (status) {
            case "Новая яма":
                return "to_work"
            case "Исправили":
                return ""
            case "В работе":
                return "fixed"
            default:
                return "to_work"
        }
    }

    const createClusterCustomIcon = (cluster: any) => divIcon({
        html: `<span class="cluster-icon">${cluster.getChildCount()}</span>`,
        className: "custom-marker-cluster",
        iconSize: point(33, 33, true)
    });

    const handleChangeFilter = (key: string, value: string) => {
        if (filters[key] !== value) {
            if (value === "Все") {
                const newFilters = {...filters};
                delete newFilters[key];
                setFilters(newFilters);
            } else {
                setFilters({
                    ...filters,
                    [key]: value
                })
            }
            setReFetch(true);
        }
    }

    const handleClose = () => {
        setOpen(false);
        setImageFile(null);
    }

    const submitFileAndChangeStatus = () => {
        handleClose();
        onFixedHandler(tempPit.id as number, tempPit.status as string, true);
    }

    const openFileInputModal = () => {
        setOpen(true);
    }

    const onFixedHandler = async (id: number, status: string, afterModal=false) => {
        if (!afterModal && status === "В работе") {
            openFileInputModal();
            setTempPit({id, status});
            return null;
        }
        try {
            const newStatus = status === "Новая яма"
                ? "В работе"
                : status === "В работе"
                    ? "Исправили"
                    : "В работу"
            await changePitStatus(id, newStatus);
            setTempPit({});
            setReFetch(true);
            setNotification({
                open: true,
                type: "success",
                message: t("status_changed_successfully")
            })
        } catch (e) {
            setNotification({
                open: true,
                type: "error",
                message: t("status_change_failed")
            })
        }
        return null;
    }

    return (
        <Box sx={{width: "100%", minHeight: "100%", height: "100%"}}>
            <Box sx={{display: "flex", justifyContent: "space-between", alignItems: "center", mb: {xs: "1rem", md: "1.5rem"}}}>
                <Typography variant="h1" sx={{fontSize: {xs: "1.5rem", md: "2rem"}}}>{t("map_road_signs")}</Typography>
            </Box>
            <Box sx={{display: "flex", gap: {xs: ".75rem", md: "1rem"}, mb: "1rem", flexDirection: {xs: "column", md: "row"}}}>
                <SelectField
                    label={t("level")}
                    options={levels}
                    value={filters.level}
                    onChange={(value: string) => handleChangeFilter("level", value)}
                />
                <SelectField
                    label="Статус"
                    options={statuses}
                    value={filters.status}
                    onChange={(value: string) => handleChangeFilter("status", value)}
                />
            </Box>
            <MapContainer
                className="map-container"
                center={[43.2567, 76.9286]} zoom={13}
            >
                <TileLayer
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                <MarkerClusterGroup
                    chunkedLoading
                    iconCreateFunction={createClusterCustomIcon}
                >
                    { markers.map((marker: any, index: number) => (
                        <Marker key={index} position={[parseFloat(marker.latitude), parseFloat(marker.longitude)]} icon={getIconByLevel(marker.level)}>
                            <Popup>
                                <Box sx={{display: "flex", flexDirection: {xs: "column", md: "row"}, gap: "1rem"}}>
                                    <Box sx={{position: "relative", width: {xs: "100%", md: "35%"}, height: {xs: "150px", md: "120px"}, borderRadius: "4px", overflow: "hidden"}}>
                                        {/*<ModalImage*/}
                                        {/*    // className={styles['table__image']}*/}
                                        {/*    large={marker.image}*/}
                                        {/*    medium={marker.image}*/}
                                        {/*    small={marker.image}*/}
                                        {/*    alt=''*/}
                                        {/*/>*/}
                                        <Link to={marker.image} target="_blank">
                                            <img
                                                // onClick={() => setFullscreenImage({open: true, image: marker.image})}
                                                style={{position: "absolute", width: "100%", height: "100%", top: "0", left: "0"}}
                                                src={marker.image}
                                                alt="pit"
                                            />
                                        </Link>
                                    </Box>
                                    <Box>
                                        <Typography sx={{fontSize: "1.375rem"}}>
                                            <strong>{t("latitude")}:</strong> {parseFloat(marker.latitude).toFixed(5)}
                                        </Typography>
                                        <Typography sx={{fontSize: "1.375rem"}}>
                                            <strong>{t("longitude")}:</strong> {parseFloat(marker.longitude).toFixed(5)}
                                        </Typography>
                                        <Typography sx={{fontSize: "1.375rem"}}>
                                            <strong>{t("created_at")}:</strong> {dayjs(marker.created_at).format("DD, MMMM YYYY")}
                                        </Typography>
                                        <Typography sx={{fontSize: "1.375rem"}}>
                                            <strong>{t("level")}:</strong> {marker.level}
                                        </Typography>
                                        <Typography sx={{fontSize: "1.375rem"}}>
                                            <strong>Статус:</strong> {marker.status}
                                        </Typography>
                                        { marker.status !== "Исправили" &&
                                            <Button
                                                sx={{mt: "1rem", color: "white", backgroundColor: "green", padding: ".5rem 1rem",
                                                    ":hover": {
                                                        color: "white", backgroundColor: "green",
                                                        opacity: ".75"
                                                    }
                                                }}
                                                onClick={() => onFixedHandler(marker.id, marker.status)}
                                            >
                                                {t(getButtonLabel(marker.status))}
                                            </Button>
                                        }
                                    </Box>
                                </Box>
                            </Popup>
                        </Marker>
                    ))}
                </MarkerClusterGroup>
            </MapContainer>
            <Box sx={{mt: {xs: ".25rem", md: ".75rem"}, display: "flex", justifyContent: {xs: "start", md: "center"}, flexWrap: "wrap", gap: {xs: ".25rem", md: "1rem"}}}>
                <Box sx={{display: "flex", alignItems: "center", gap: ".25rem"}}>
                    <img src={MarkerPedestrianIcon} style={{width: "28px", height: "28px"}} alt="Разметка пешехода стирается" />
                    - {t("pedestrian_line_erasing")}
                </Box>
                <Box sx={{display: "flex", alignItems: "center", gap: ".25rem"}}>
                    <img src={MarkerArrowSignIcon} style={{width: "28px", height: "28px"}} alt="Стрелка поворота стирается" />
                    - {t("arrow_erasing")}
                </Box>
            </Box>
            {/*<Modal*/}
            {/*    open={fullscreenImage.open}*/}
            {/*    onClose={() => setFullscreenImage({open: false, image: ""})}*/}
            {/*    aria-labelledby="modal-modal-title"*/}
            {/*    aria-describedby="modal-modal-description"*/}
            {/*>*/}
            {/*    <Box sx={modalStyle}>*/}
            {/*        <img src={fullscreenImage.image} alt="full" />*/}
            {/*    </Box>*/}
            {/*</Modal>*/}
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={modalStyle}>
                    <Typography sx={{textAlign: "center", mb: "1rem"}} id="modal-modal-title" variant="h6" component="h2">
                        {t("add_photo")}
                    </Typography>
                    <FileInputField label="" value={imageFile} onChange={value => setImageFile(value)} />
                    <CustomButton label={t("submit")} onChange={submitFileAndChangeStatus} style={{width: "100%", marginTop: "1rem"}} />
                </Box>
            </Modal>
        </Box>
    )
}

export default RoadSignsMap;
import {FC} from "react";
import {Link, useLocation} from "react-router-dom";
import {Box, ListItemButton, ListItemIcon, ListItemText, Tooltip} from "@mui/material";
import * as React from "react";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import {useTranslation} from "react-i18next";

type ItemProps = {
    disabled: boolean,
    item: any,
    handleToggleSubMenu: any,
    theme: any,
    open: any,
    openSubMenus?: any,
}
const Item: FC<ItemProps>  = ({ disabled=false, item, handleToggleSubMenu, theme, open, openSubMenus = {}}) => {
    const {pathname} = useLocation();
    const { t } = useTranslation();
    let isActive;
    if (pathname === "/" && item.href === "/") {
        isActive = true;
    } else if (pathname !== "/" && item.href !== "/" && pathname.includes(item.href)) {
        isActive = pathname.includes(item.href);
    }
    const title = disabled ? `Не хватает прав для открытия раздела '${item.label}'` : item.label;
    return (
        <Tooltip sx={{fontSize: "2rem"}} title={title} placement="right" disableHoverListener={disabled ? false : open}>
            <ListItemButton
                disabled={disabled}
                sx={{
                    background: isActive ? "rgba(106, 98, 210, 0.1)" : "initial",
                    borderRight: isActive ? "2px solid #4361ee" : "2px solid transparent",
                    minHeight: 48,
                    justifyContent: open ? "initial" : "center",
                    px: 2.5,
                    cursor: "pointer !important",
                    pointerEvents: "visible !important",
                    "&:hover": {
                        background: "rgba(106, 98, 210, 0.1)",
                        borderRight: "2px solid #4361ee",
                    },

                }}
                component={item.href && !item.children
                    ? !disabled ? Link : Box
                    : Box}
                to={item.href && !item.children ? item.href : undefined}
                onClick={(event: React.MouseEvent) => {
                    if (item.children) {
                        event.preventDefault();
                        event.stopPropagation();
                    }
                    handleToggleSubMenu(item.key);
                }}
            >
                <ListItemIcon
                    sx={{
                        minWidth: 0,
                        mr: open ? 3 : "auto",
                        justifyContent: "center",
                        color: "text.primary",
                    }}
                >
                    <Box sx={{ display: "flex", justifyContent: "center", alignItems:"center" }}>
                        <item.Icon style={{width: "2rem", height: "2rem"}} />
                    </Box>
                </ListItemIcon>
                <ListItemText primary={t(item.label)} sx={{ opacity: open ? 1 : 0, whiteSpace: "wrap"}} />
                {item.children && open && openSubMenus && (openSubMenus[item.key] ? <ExpandLess /> : <ExpandMore />)}
            </ListItemButton>
        </Tooltip>
    )
}

export default Item;
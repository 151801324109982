import {FieldError} from "react-hook-form";
import {FC} from "react";
import dayjs from "dayjs";
import {useTranslation} from "react-i18next";
import DatePickerCustom from "../DatePicker";
import MultiSelect from "../../UI/InputFields/MultiSelect";
import {OptionObjectType} from "../../services/common-types";
import FormTextField from "../../UI/InputFields/FormTextField";
import FileField from "../../UI/InputFields/FileField";
import SelectField from "../../UI/InputFields/SelectField";
import CheckBoxField from "../../UI/InputFields/CheckboxField";
import MultipleCheckBox from "../../UI/InputFields/MultipleCheckBox";

type InputProps = {
    label: string,
    hint: string,
    value: string | string[] | number | boolean | File,
    type: string,
    errors: FieldError,
    onChange: (e: any) => void,
    readOnly: boolean,
    options: Array<string | OptionObjectType>,
    rules: {
        [key: string]: {
            value: boolean,
            message: string
        }
    }
}

const Input: FC<InputProps> = ({ label, value, hint, onChange, type, errors, ...rest }) => {
    const lang = localStorage.getItem("lng") || "ru";
    const isError = Object.keys(errors).length > 0;
    // @ts-ignore
    const errorMessage = Object.keys(errors?.message || {}).length <= 0 ? "" : errors?.message[lang];

    switch (type) {
        case "date":
            return (
                <DatePickerCustom
                    value={value as string}
                    onChange={newValue => onChange(dayjs(newValue).format("YYYY-MM-DD"))}
                    style={{  width: "360px", paddingTop: "-8px" }}
                    // disableBorders
                />
            )
        case "string":
            return (
                <FormTextField
                    label={label}
                    InputProps={{
                        readOnly: rest.readOnly,
                    }}
                    sx={{mb: isError ? "38px" : "24px"}}
                    disabled={false}
                    error={isError}
                    id="standard-error-helper-text"
                    helperText={errorMessage}
                    variant="outlined"
                    value={value as string}
                    onChange={onChange}
                />
            );
        case "textarea":
            return (
                <FormTextField
                    InputProps={{
                        readOnly: rest.readOnly,
                    }}
                    sx={{mb: isError ? "38px" : "24px"}}
                    error={isError}
                    helperText={errorMessage}
                    id="outlined-multiline-static"
                    label={label}
                    multiline
                    rows={8}
                    defaultValue={value as any}
                    value={value as any}
                    onChange={onChange}
                />
            );
        case "number":
            return (
                <FormTextField
                    type="number"
                    InputProps={{
                        readOnly: rest.readOnly,
                    }}
                    sx={{mb: isError ? "38px" : "24px"}}
                    disabled={false}
                    error={isError}
                    id="standard-error-helper-text"
                    label={label}
                    helperText={errorMessage}
                    variant="standard"
                    value={value as any}
                    onChange={onChange}
                />
            );
        case "checkbox":
            return (
                <CheckBoxField
                    label={label}
                    value={value as boolean}
                    onChange={onChange}
                    readOnly={rest.readOnly}
                />
            );
        case "multiple-checkboxes":
            return (
                <MultipleCheckBox
                    label={label}
                    value={value as string[]}
                    onChange={onChange}
                    options={rest.options}
                />
            )
        case "select":
            return (
                <SelectField
                    label={label}
                    value={value as string}
                    // sx={{mb: "24px"}}
                    onChange={onChange}
                    options={rest.options as string[]}
                    // errors={errors}
                    // readOnly={rest.readOnly}
                />
            );
        case "file":
            return (
                <FileField
                    label="Upload File"
                    value={value}
                    onChange={file => onChange(file)}
                    errors={errors}
                />
            )
        case "multiselect":
            return (
                <MultiSelect
                    value={value}
                    label="MultiSelect"
                    rest={rest}
                    options={rest.options}
                    onChange={onChange}
                    errors={errors}
                    // {...field}
                />
            )
        default:
            return null;
    }
};

export default Input;
